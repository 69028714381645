import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import HeaderMobile from "@/components/Header/mobile-header";
import Image from "@/components/Image";
import "./styles.css";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const Header = () => {
  const [sticky, setStickyClass] = useState("");
  const { pathname } = useLocation();
  const isActiveLink = (path: string) => {
    return pathname === path ? "active" : "";
  };
  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      const doc = event.target as Document;
      const scroll = doc.scrollingElement?.scrollTop
        ? doc.scrollingElement?.scrollTop
        : 0;

      if (scroll > 100) {
        setStickyClass("fixed-menu");
      } else {
        setStickyClass("");
      }
    });

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);
  return (
    <>
      <header>
        <div className="top-head">
          <div className="container">
            <div className="top-menu">
              <div className="row">
                <div className="col-lg-3">
                  <OutboundLink href="/">
                    {" "}
                    <Image name="logo.png" classes="img-logo" />{" "}
                  </OutboundLink>
                </div>
                <div className="col-lg-7">
                  <ul className="contact-top list-unstyled">
                    <li>
                      <span className="d-block color-white transition-all">
                        {" "}
                        <i className="bi bi-telephone-forward"></i> Phone Number{" "}
                      </span>
                      <OutboundLink
                        href={"tel:" + "+13053968973"}
                        className="d-block color-white transition-all"
                        style={{
                          textDecoration: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        +1 (305) 396-8973{" "}
                      </OutboundLink>
                    </li>
                    <li>
                      <span className="d-block color-white transition-all">
                        {" "}
                        <i className="bi bi-envelope"></i> Email Address{" "}
                      </span>
                      <OutboundLink
                        style={{
                          textDecoration: "none",
                          fontWeight: "bold",
                        }}
                        href={"mailto:" + "startprofessionalcare@yahoo.com"}
                        className="d-block color-white transition-all"
                      >
                        {" "}
                        startprofessionalcare@yahoo.com{" "}
                      </OutboundLink>
                    </li>
                    <li>
                      <span className="d-block color-white transition-all">
                        {" "}
                        <i className="bi bi-geo-alt"></i> Location{" "}
                      </span>
                      <OutboundLink
                        style={{
                          textDecoration: "none",
                          fontWeight: "bold",
                        }}
                        href="https://goo.gl/maps/43Yka5NUMQcWSBwP6"
                        target="_blank"
                        className="d-block color-white transition-all"
                      >
                        {" "}
                        Hialeah Gardens, FL 33018{" "}
                      </OutboundLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={`mn-head shadow ${sticky}`}>
              <div className="container app-br">
                <nav className="navbar navbar-expand-lg navbar-light app-br">
                  <OutboundLink className="navbar-brand d-lg-none" href="#">
                    <img
                      src="images/logo.png"
                      alt="pic"
                      style={{ width: "30%" }}
                    />
                  </OutboundLink>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample"
                    aria-controls="offcanvasExample"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse d-none d-lg-none"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav mb-2 mb-lg-0 align-items-lg-center">
                      <li className={`nav-item ${isActiveLink("/")}`}>
                        <Link className="nav-link" to="/">
                          Home
                        </Link>
                      </li>

                      <li className={`nav-item ${isActiveLink("/about")}`}>
                        <Link className="nav-link" to="/about">
                          About
                        </Link>
                      </li>

                      <li className={`nav-item ${isActiveLink("/services")}`}>
                        <Link className="nav-link" to="/services">
                          Services{" "}
                        </Link>
                      </li>

                      <li className={`nav-item ${isActiveLink("/contact")}`}>
                        <Link className="nav-link" to="/contact">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      <HeaderMobile />
    </>
  );
};

export default Header;
