import React, { useEffect } from "react";
import { Link } from "gatsby";

const Mb = () => {
  const removeOverlay = () => {
    const overlay = document.querySelector(".modal-backdrop");
    const body = document.getElementsByTagName("body").item(0);
    const canvasActivate = document.querySelector(".offcanvas-start");

    if (overlay && body && canvasActivate) {
      body.classList.remove("main");
      canvasActivate.classList.remove("show");
      overlay.classList.remove("show");
      body.style.removeProperty("overflow");
      body.style.removeProperty("paddingRight");
      overlay.classList.remove("show");
      overlay.remove();
    }
    return;
  };
  useEffect(() => {
    const body = document.getElementsByTagName("body").item(0);
    const offCanvasToggle = document.querySelector(".navbar-toggler");
    const canvasActivate = document.querySelector(".offcanvas-start");
    const btnCanvasClose = document.querySelector(".ccl");
    const offcanvasId = document.getElementById("offcanvasId");
    const div = document.createElement("div");

    if (
      body &&
      offcanvasId &&
      offCanvasToggle &&
      canvasActivate &&
      btnCanvasClose
    ) {
      offCanvasToggle.addEventListener("click", (e) => {
        e.preventDefault();
        body.classList.add("main");
        body.style.overflow = "hidden";
        body.style.paddingRight = "0px";
        canvasActivate.classList.add("show");
        offcanvasId.style.visibility = "visible";
        div.classList.add("modal-backdrop");
        div.classList.add("fade");
        div.classList.add("show");
        body.appendChild(div);
        div.addEventListener("click", (e) => {
          e.preventDefault();
          body.classList.remove("main");
          canvasActivate.classList.remove("show");
          div.classList.remove("show");
          body.style.removeProperty("overflow");
          body.style.removeProperty("paddingRight");
          div.classList.remove("show");
          div.remove();
        });
      });

      btnCanvasClose.addEventListener("click", (e) => {
        e.preventDefault();
        body.classList.remove("main");
        canvasActivate.classList.remove("show");
        div.classList.remove("show");
        body.style.removeProperty("overflow");
        body.style.removeProperty("paddingRight");
        div.classList.remove("show");
        div.remove();
      });
    }

    return () => {
      window.removeEventListener("click", () => {});
    };
  }, []);
  return (
    <div id="offcanvasId" className="offcanvas offcanvas-start" tabIndex={-1}>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title">
          <img src="images/logo.png" alt="logo-medical" style={{width:'50%'}}/>
        </h5>
        <button
          id="btnCanvasClose"
          type="button"
          className="btn-close text-reset ccl"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <div>
          <div className="mobile-show-menu">
            <ul>
              <li>
                {" "}
                <Link to="/" onClick={() => removeOverlay()}>
                  {" "}
                  Home{" "}
                </Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/about" onClick={() => removeOverlay()}>
                  {" "}
                  About{" "}
                </Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/services" onClick={() => removeOverlay()}>
                  {" "}
                  Services{" "}
                </Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/contact" onClick={() => removeOverlay()}>
                  {" "}
                  Contact{" "}
                </Link>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mb;
