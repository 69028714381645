
import React from "react";
import Image from "@/components/Image";
import { Link } from "gatsby";
import "@/components/Footer/style.css";
import { OutboundLink } from "gatsby-plugin-google-gtag";


const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2">
          <div className="col-md-4">
            <div className="comon-footer">
              <Image name="logo-footer.png" classes="footer-logo" />
              <p className="text-white">
                {' '}
                Start Professional Care & Support LLC was founded with the
                objective of delivering high quality care, helping and
                supporting the community and clients with special needs in all
                their daily activities.{' '}
              </p>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row mt-3 footer-col row-cols-md-4">
              <div className="col">
                <div className="comon-footer">
                  <h5> ABOUT </h5>
                  <ul>
                    <li>
                      {' '}
                      <OutboundLink href="/about"> About Us </OutboundLink>{' '}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col">
                <div className="comon-footer">
                  <h5> Services </h5>
                  <ul>
                    <li>
                      <Link
                        to={'/services'}
                        state={{ showActiveTab: 'personalCare' }}
                      >
                        Personal Care
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={'/services'}
                        state={{ showActiveTab: 'personalSupport' }}
                      >
                        Personal Support
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={'/services'}
                        state={{ showActiveTab: 'homemaker' }}
                      >
                        Homemaker
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={'/services'}
                        state={{ showActiveTab: 'respite' }}
                      >
                        Respite
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={'/services'}
                        state={{ showActiveTab: 'lifeSkill' }}
                      >
                        Life Skill
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={'/services'}
                        state={{ showActiveTab: 'privateDutyNursing' }}
                      >
                        Private Duty Nursing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={'/services'}
                        state={{ showActiveTab: 'residentialNursing' }}
                      >
                        Residential Nursing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={'/services'}
                        state={{ showActiveTab: 'skilledNursing' }}
                      >
                        Skilled Nursing
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col">
                <div className="comon-footer">
                  <h5> CONTACT </h5>
                  <ul>
                    <li>
                      {' '}
                      <OutboundLink href="/contact">
                        {' '}
                        Contact Us{' '}
                      </OutboundLink>{' '}
                    </li>
                    <li>
                      {' '}
                      <OutboundLink
                        href={'mailto:' + 'startprofessionalcare@yahoo.com'}
                      >
                        {' '}
                        Book an Appointment{' '}
                      </OutboundLink>{' '}
                    </li>
                    <li>
                      {' '}
                      <OutboundLink href="/about">
                        {' '}
                        Request Info{' '}
                      </OutboundLink>{' '}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="comon-footer">
                  <h5> OTHER </h5>
                  <ul>
                    <li>
                      {' '}
                      <OutboundLink href="/privacy-policy">
                        {' '}
                        Privay Policy{' '}
                      </OutboundLink>{' '}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="bg-white" />
      <div className="container">
        <div className="d-lg-flex justify-content-between">
          <div className="col-lg-7 text-white text-footer">
            {' '}
            {`© ${new Date().getFullYear()} `}{' '}
            <Link
              to="https://startprofessionalcare.com"
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              Start Professional Care
            </Link>{' '}
            Powered by{' '}
            <OutboundLink
              href="https://www.nimbuspc.com"
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              NimbusPC
            </OutboundLink>{' '}
            {/* <Link
              to="/privacy-policy"
              className="text-white"
              style={{ textDecoration: 'none', marginLeft: '1rem' }}
            >
              Privay Policy
            </Link> */}
          </div>
          <div className="col-lg-5 social">
            <ul className="list-unstyled socal">
              <li>
                {' '}
                <OutboundLink href="#">
                  {' '}
                  <i className="bi bi-facebook"></i>{' '}
                </OutboundLink>
                <OutboundLink href="#">
                  {' '}
                  <i className="bi bi-twitter"></i>{' '}
                </OutboundLink>
                <OutboundLink href="#">
                  {' '}
                  <i className="bi bi-instagram"></i>
                </OutboundLink>{' '}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
