import * as React from "react";

import "@/css/bootstrap.min.css";
import "@/css/bootstrap-icons.css";
import "@/css/fontRubik.css";
import "@/css/fontExo.css";
import "@/css/all.min.css";
import "@/css/font-awesome.min.css";
import "@/css/style.css";

// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';



const Layout: React.FC = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Layout;
