import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { ImageProps } from "@/types/image";

const Image = ({ name, classes }: ImageProps) => {
  const data = useStaticQuery(graphql`
    query Image {
      allFile(filter: { extension: { regex: "/(jpg)|(png)|(jpeg)|(svg)|(webp)/" } }) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const image = data.allFile.edges.find(({ node }: any) => node.base === name);

  return (
    <Img
      fluid={image.node.childImageSharp.fluid}
      alt="image"
      className={classes}
    />
  );
};

export default Image;
